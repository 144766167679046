import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 44 50"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      id="Locked"
      className="cls-1"
      d="M36,50H8a8,8,0,0,1-8-8V23.5A1.5,1.5,0,0,1,1.5,22H6V16C6,6.281,12.281,0,22,0S38,6.281,38,16v6h4.5A1.5,1.5,0,0,1,44,23.5V42A8,8,0,0,1,36,50ZM6,42a2,2,0,0,0,2,2H36a2,2,0,0,0,2-2V28H6V42ZM32,16C32,9.552,28.448,6,22,6S12,9.552,12,16v6H32V16Z"
    />
  </svg>
))
