import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130 136" {...props}>
    <linearGradient
      id="linear-gradient"
      x1="65"
      y1="135.969"
      x2="65"
      y2="-0.031"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" />
      <stop offset="1" />
    </linearGradient>
    {children}
    <path
      id="O"
      className="cls-1"
      fill="url(#linear-gradient)"
      fillRule="evenodd"
      d="M130,69.51a49.072,49.072,0,0,1-21.729,40.782L74.014,133.231a16.187,16.187,0,0,1-18.028,0L21.729,110.292A49.071,49.071,0,0,1,0,69.51V16.3A16.287,16.287,0,0,1,16.25-.025h97.5A16.287,16.287,0,0,1,130,16.3V69.51ZM71.586,86.3A20.591,20.591,0,0,1,61,68V54c0-8.462-4.88-14-13-14S35,45.59,35,54.053V67.513C35,83.644,44.937,94,60.417,94A28.352,28.352,0,0,0,71.3,91.975C74.451,90.672,74.582,87.962,71.586,86.3ZM95,54.487V67.947C95,76.41,90.12,82,82,82S69,76.462,69,68V54A20.591,20.591,0,0,0,58.414,35.7c-3-1.66-2.866-4.37.289-5.674A28.35,28.35,0,0,1,69.583,28C85.063,28,95,38.356,95,54.487Z"
    />
  </svg>
))
