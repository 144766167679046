import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 48 50"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      id="Briefcase"
      className="cls-1"
      d="M0,42V13.5A1.5,1.5,0,0,1,1.5,12H12V8a8,8,0,0,1,8-8h8a8,8,0,0,1,8,8v4H46.5A1.5,1.5,0,0,1,48,13.5V42a8,8,0,0,1-8,8H8A8,8,0,0,1,0,42ZM28,6H20a2,2,0,0,0-2,2v4H30V8A2,2,0,0,0,28,6ZM42,18H6v8H42V18Zm0,14H6V42a2,2,0,0,0,2,2H40a2,2,0,0,0,2-2V32Z"
    />{" "}
  </svg>
))
