import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    stroke="currentColor"
    viewBox="0 0 50 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      id="Binder"
      className="cls-1"
      fill="currentColor"
      d="M42,48H7.5A1.5,1.5,0,0,1,6,46.5V39H1.5A1.5,1.5,0,0,1,0,37.5v-3A1.5,1.5,0,0,1,1.5,33H6V27H1.5A1.5,1.5,0,0,1,0,25.5v-3A1.5,1.5,0,0,1,1.5,21H6V15H1.5A1.5,1.5,0,0,1,0,13.5v-3A1.5,1.5,0,0,1,1.5,9H6V1.5A1.5,1.5,0,0,1,7.5,0H42a8,8,0,0,1,8,8V40A8,8,0,0,1,42,48ZM12,6V42h6V6H12ZM44,8a2,2,0,0,0-2-2H24V42H42a2,2,0,0,0,2-2V8Z"
    />{" "}
  </svg>
))
