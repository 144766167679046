import { graphql } from "gatsby"
import React, { FC } from "react"
import Card from "src/components/Card"
import { Layout } from "src/components/Layout"
import Jumbotron from "src/components/Jumbotron"
import { Props, Resource } from "./types"
import * as styles from "./styles.module.scss"
import Globe from "src/components/Icons/Globe"
import Binder from "src/components/Icons/Binder"
import SecurityFlow from "src/components/Icons/SecurityFlow"
import Lock from "src/components/Icons/Lock"
import Briefcase from "src/components/Icons/Briefcase"

import RowLink from "src/components/RowLink"
import { usePages } from "src/context/pages"
import Logo from "src/images/structured-data-logo.png"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => {
  const { getPage, currentPath } = usePages()

  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
      headerClassname={`white-nav`}
    >
      <Jumbotron title={texts.header} subtitle={texts.subheader} />
      <div className={`content-wrapper ${styles.cards}`}>
        <Card
          title={texts.sections[0].title}
          description={texts.sections[0].description}
          url={getPage(texts.sections[0].url).url}
          linkText={texts.readMore}
        >
          <Briefcase />
        </Card>
        <Card
          title={texts.sections[1].title}
          description={texts.sections[1].description}
          url={getPage(texts.sections[1].url).url}
          linkText={texts.readMore}
        >
          <Lock />
        </Card>
        <Card
          title={texts.sections[2].title}
          description={texts.sections[2].description}
          url={getPage(texts.sections[2].url).url}
          linkText={texts.readMore}
        >
          <Globe />
        </Card>
        <Card
          title={texts.sections[3].title}
          description={texts.sections[3].description}
          url={getPage(texts.sections[3].url).url}
          linkText={texts.readMore}
        >
          <Binder />
        </Card>
        <Card
          title={texts.sections[4].title}
          description={texts.sections[4].description}
          url={getPage(texts.sections[4].url).url}
          linkText={texts.readMoreSecurity}
          className={styles.cardBig}
          whiteCard={true}
        >
          <SecurityFlow />
        </Card>
      </div>
      <div className={`content-wrapper ${styles.resources}`}>
        <h2 className={styles.sectionTitle}>
          {texts.additionalResourcesTitle}
        </h2>
        {texts.additionalResources.map((resource: Resource) => (
          <RowLink title={resource.title} url={getPage(resource.url).url} />
        ))}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        header
        subheader
        metaTitle
        metaDescription
        sections {
          title
          description
          url
        }
        readMore
        readMoreSecurity
        additionalResourcesTitle
        additionalResources {
          title
          url
        }
      }
    }
  }
`

export default Template
