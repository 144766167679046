import React, { FC } from "react"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import ArrowLink from "src/components/ArrowLink"
import Link from "src/utils/Link"

const Card: FC<Props> = ({
  description,
  title,
  url,
  linkText,
  className,
  whiteCard = false,
  children,
}) => {
  return (
    <Link
      to={url}
      className={`${styles.card} ${className} ${
        whiteCard ? styles.whiteCard : ""
      }`}
    >
      <div className={styles.header}>{children}</div>
      <div className={styles.body}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{description}</p>
        <ArrowLink as="span" text={linkText} to={url} className={styles.link} />
      </div>
    </Link>
  )
}

export default Card
