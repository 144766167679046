import React, { FC } from "react"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import LinkArrow from "src/components/Icons/LinkArrow"
import Link from "src/utils/Link"

const RowLink: FC<Props> = ({ title, url, target }) => (
  <Link className={styles.row} to={url} target={target}>
    <h5 className={styles.title}>{title}</h5>
    <div className={styles.icon}>
      <LinkArrow />
    </div>
  </Link>
)

export default RowLink
